<template>
    <div class="qty-setter d-flex align-center">
        <v-btn small icon text :disabled="minusDisabled" color="primary" @click="onMinusClicked">
            <v-icon size="18">{{ icon.mdiMinus }}</v-icon>
        </v-btn>

        <div class="qty-input">
            <FormInput
                type="number"
                :fieldValue.sync="controlQty"
                hideDetails
                isIntegerOnly
                dense
                @input-value="updateValue"
                @onblur="onBlur"
                :disabled="inputDisabled"
            ></FormInput>
        </div>

        <v-btn small icon text :disabled="addDisabled" color="primary" @click="onAddClicked">
            <v-icon size="18">{{ icon.mdiPlus }}</v-icon>
        </v-btn>
    </div>
</template>
  
<script>
import { mdiMinus, mdiPlus } from '@mdi/js';
import FormInput from '@/components/formField/FormInput.vue';

export default {
    name: 'QtySetter',
    components: {
        FormInput
    },
    props: {
        qty: {
            type: Number,
            required: true,
            default: 1,
        },
        max: {
            type: Number,
            required: false,
            default: -1, // -1 = no limit
        },
    },
    computed: {
        maxNum() {
            return this.$validate.DataValid(this.max) && this.$validate.regexNumber(this.max) ? this.max : -1;
        },
        minusDisabled() {
            if (parseInt(this.controlQty) <= 1) {
                return true;
            }

            return false;
        },
        addDisabled() {
            if (this.maxNum !== -1 && parseInt(this.controlQty) >= this.maxNum) {
                return true;
            }

            return false;
        },
        inputDisabled() {
            if (this.maxNum === 0) {
                return true;
            }

            return false;
        },
        controlQty: {
            get() {
                return this.qty.toString();
            },
            set(val) {
                if (this.$validate.DataValid(val)) {
                    this.$emit('update:qty', parseInt(val));
                    this.$emit('update-value', parseInt(val));
                }
            }
        }
    },
    data: () => ({
        icon: {
            mdiMinus,
            mdiPlus
        },
        // controlQty: '1',
    }),
    watch: {
        qty(val) {
            this.controlQty = val.toString();
        }
    },
    methods: {
        onMinusClicked() {
            if (parseInt(this.controlQty) > 1) {
                this.controlQty = (parseInt(this.controlQty) - 1).toString();
            }
            // this.$emit('update:qty', parseInt(this.controlQty));
            // this.$emit('update-value', parseInt(this.controlQty));
        },
        onAddClicked() {
            if (this.maxNum === -1 || parseInt(this.controlQty) < this.maxNum) {
                this.controlQty = (parseInt(this.controlQty) + 1).toString();
            }
            // this.$emit('update:qty', parseInt(this.controlQty));
            // this.$emit('update-value', parseInt(this.controlQty));
        },
        updateValue(val) {
            if (this.$validate.DataValid(val)) {
                // this.$emit('update:qty', parseInt(val));
                // this.$emit('update-value', parseInt(val));
            }
        },
        onBlur() {
            if (!this.$validate.DataValid(this.controlQty) || parseInt(this.controlQty) < 1) {
                this.controlQty = '1';
                // this.$emit('update:qty', 1);
                // this.$emit('update-value', 1);
                // return;
            } else if (this.maxNum !== -1 && parseInt(this.controlQty) > this.maxNum) {
                this.controlQty = this.maxNum.toString();
                // this.$emit('update:qty', this.maxNum);
                // this.$emit('update-value', this.maxNum);
                // return;
            }

        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep.qty-setter {
    & > .qty-input {
        flex: 1;
        max-width: 50px;

        & .v-input__slot {
            border: none;
            background: transparent;
        }

        & input {
            text-align: center;
        }
    }

}
</style>
  